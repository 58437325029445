import React, {useEffect} from 'react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Deparments from '../../components/Departments';

const FaqPage = () => {
  return (
    <Layout pageTitle='Departmental | LUTH'>
      <NavOne />
      <PageHeader title='Departments' />
      <Deparments />
      <Footer />
    </Layout>
  );
};

export default FaqPage;
